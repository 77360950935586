import ChangeLog from '@/modules/common/components/changelog/changelog.vue'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Watch, Mixins } from 'vue-property-decorator'
import DesignPageMixin from '@/modules/common/components/mixins/design-page.mixin'
import SettingsPageMixin from '@/modules/common/components/mixins/settings-page.mixin'
import { UserInstructionSet } from '@/interfaces/common.interface'
import { userInstructionSet_Design } from '@/bootstrap/global/user-instructions'

@Component({
  name: 'GtrEvalsModuleDesignView',
  components: {
    changelog: ChangeLog
  }
})
export default class GtrEvalsModuleDesignView extends Mixins(SettingsPageMixin, DesignPageMixin) {
  private readonly settings_type = 'evals_design'

  userInstructionSets: UserInstructionSet[] = userInstructionSet_Design;

  // #region Life Cycle Callbacks
  async mounted () {
    this.$store.commit('design/SET_DESIGN', [])
    this.$store.commit('design/SET_DESIGN_TEMPLATES', [])
    this.$store.commit('design/SET_DESIGN_FIELDS', [])
    this.$store.commit('option/SET_OPTION_GROUPS', [])
    await this.fetchSettings('design/getEvalsDesign')
    await this.fetchSettingsFields('design/getEvalsDesignFields')
    await this.fetchChangelog(this.settings_type)
    await this.fetchCurrentlyDeployedLiveUUID(this.settings_type)
    this.$store.dispatch('common/hideLoader')
  }
  // #endregion

  // #region Watchers
  @Watch('designVersion')
  async onDesignVersionChange (payload: any) {
    if (payload !== 'default') {
      try {
        this.loading = true
        const revisionUuid = this.$data._changelog.data.filter((row: any) => row.uuid === payload).pop().uuid
        await this.$store.dispatch('design/getSettingsByRevisionUuid', { event_uuid: this.event_uuid, revision_uuid: revisionUuid })
        Container.get(Notification).success('Design version successfully changed.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.loading = false
      }
    }
  }

  @Watch('designLoaded', { deep: true })
  onDesignLoaded (payload: any) {
    if (payload) {
      const designObject = {}
      const languages = this.$store.state.languages.languages
      for (const category in this.designFields) {
        designObject[category] = {}
        for (const setting in this.designFields[category]) {
          designObject[category][setting] = {}
          designObject[category][setting].name = setting
          designObject[category][setting].type = this.designFields[category][setting].type
          designObject[category][setting].display_name = this.designFields[category][setting].display_name
          designObject[category][setting].description = this.designFields[category][setting].description
          designObject[category][setting].value = {}
          designObject[category][setting].temp = null
          if (this.designFields[category][setting].type === 'select') {
            designObject[category][setting].values = this.designFields[category][setting].values
          }
          for (let i = 0; i < this.registrationTypes.length; i++) {
            const regTypeUUID = this.registrationTypes[i].value
            designObject[category][setting].value[regTypeUUID] = ''
            if (this.designFields[category][setting].default !== 'undefined') {
              if (this.designFields[category][setting].type === 'url') {
                designObject[category][setting].value[regTypeUUID] = this.designFields[category][setting].default
                designObject[category][setting].value[this.parseSetting(this.registrationTypes[i].text) + '_temp_file'] = null
              } else {
                designObject[category][setting].value[regTypeUUID] = this.designFields[category][setting].default
              }
            }
          }
        }
      }
      /**
       * Fill the design object with values saved from the database
       */
      if (this.design.page_data) {
        for (const setting in this.design.page_data) {
          for (const category in designObject) {
            if (designObject[category][setting]) {
              for (const value in this.design.page_data[setting]) {
                designObject[category][setting].value[value] = this.design.page_data[setting][value]
              }
            }
          }
        }
      }

      this.settingsObject = designObject

      const templateObject = {}
      for (let i = 0; i < this.registrationTypes.length; i++) {
        const { value } = this.registrationTypes[i]
        templateObject[value] = {
          current_template: 'default',
          template_data: Object.assign({}, this.templateSettings)
        }
      }

      this.template = templateObject
    }
  }
  // #endregion

  async submit () {
    try {
      this.submitting = true
      const designData: Record<string, any> = {
        event_uuid: this.event_uuid
      }
      const key = this.templateToUse
      for (const setting in this.settingsObject[key]) {
        if (this.settingsObject[key][setting].type === 'url') {
          for (const temp_file in this.settingsObject[key][setting].value) {
            if (temp_file.indexOf('temp_file') !== -1) {
              continue
            }
            const fileOrUrl = this.settingsObject[key][setting].value[temp_file]
            let url = fileOrUrl
            if (typeof fileOrUrl === 'object' && fileOrUrl instanceof File) {
              const response: any = await this.$store.dispatch('media/uploadMedia', { event_uuid: this.event_uuid, fileData: fileOrUrl, fetch: false })
              url = response.responseEventMedia.data.url
            }
            this.settingsObject[key][setting].value[temp_file] = url
          }
        }
      }
      for (const category in this.settingsObject) {
        for (const setting in this.settingsObject[category]) {
          if (this.settingsObject[category][setting].type === 'url') { // if the type is a url.
            const temp = this.settingsObject[category][setting].value // store the value of the setting.
            // setup a loop.
            let j = 0
            let count = Object.keys(temp).length

            // while j is less then count.
            while (j < count) {
              if (Object.keys(temp)[j].includes('temp')) { // if it has the word temp, delete it.
                delete temp[Object.keys(temp)[j]]
                count-- // decrement count.
              }
              j++ // increment j.
            }
            delete temp.temp_file
            designData[setting] = temp
          } else {
            designData[setting] = this.settingsObject[category][setting].value
          }
        }
      }
      /**
       * Add template stored in data() to the designData object
       */
      designData.template = this.template
      designData.template_name = this.templateToUse
      const payload: any = {
        event_uuid: this.event_uuid,
        data: designData
      }
      await this.$store.dispatch('design/saveEvalsDesign', payload)
      Container.get(Notification).success('Design successfully saved.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.submitting = false
    }
  }
  // #endregion

  parseSetting (value: any) {
    return value.split(' ').join('_').toLowerCase()
  }
}
